// Dependencies
import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";

// Material UI Components

// Icons

// Components
import ModalContainer from "components/modals/ModalContainer/ModalContainer.component";
import SentConfirmation from "components/modals/SentConfirmation/SentConfirmation.component";

// Helpers/Thunks/Hooks
import { useQueryParams } from "hooks/useQueryParams.hook";
import { modalReducer } from "reducers/modal-reducer";

// Styles

const SentConfirmationModal = () => {
  const router = useRouter();
  const { updateQueryParams } = useQueryParams();
  const dispatch = useDispatch();
  const { modal } = router.query;
  const currentModal = useSelector((s) => s.modal.modal);

  const handleClose = () => {
    dispatch(modalReducer.actions.close());
  };

  useEffect(() => {
    if (modal === "sentConfirmation") {
      dispatch(modalReducer.actions.show({ modal, variant: "default" }));
      updateQueryParams({ modal: null });
    }
  }, [modal]);

  return (
    <div
      data-component="SentConfirmationModal
  "
      data-cy="sent-confirmation-modal"
    >
      <ModalContainer
        open={currentModal === "sentConfirmation"}
        handleClose={handleClose}
        contentChildren={<SentConfirmation handleClose={handleClose} />}
      />
    </div>
  );
};

export default SentConfirmationModal;
