import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "theme";

const usePageWidth = () => {
  const isMobileWidth = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktopWidth = useMediaQuery(theme.breakpoints.up("md"));
  const isWideDesktopWidth = useMediaQuery(theme.breakpoints.up("xl"));
  const isTabletWidth = !isMobileWidth && !isDesktopWidth;
  return {
    isMobileWidth,
    isDesktopWidth,
    isTabletWidth,
    isWideDesktopWidth,
  };
};

export default usePageWidth;
