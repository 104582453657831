// Dependencies
import React from "react";

// Material UI Components
import Link from "@mui/material/Link";

// Icons

// Components

// Helpers/Thunks/Hooks
import { tosRoute, privacyRoute } from "routes";

// Styles
import { useStyles } from "./Legal.styles";

const Legal = () => {
  const classes = useStyles();

  return (
    <div className={classes.root} data-component="Legal">
      By continuing, you agree to VINN&apos;s{" "}
      <Link href={tosRoute} target="_blank" data-cy="terms-of-service">
        Terms of Service
      </Link>{" "}
      and acknowledge that you have read our{" "}
      <Link href={privacyRoute} target="_blank" data-cy="privacy-policy">
        Privacy Policy
      </Link>
    </div>
  );
};

export default Legal;
