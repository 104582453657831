import { useState, useEffect } from "react";

import { ISSERVER } from "helpers/server-helpers";

export default function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);
  let observer = null;
  if (!ISSERVER) {
    observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting),
    );
  }

  useEffect(() => {
    if (!ISSERVER) {
      observer.observe(ref.current);
    }
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
}
