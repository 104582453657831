// Dependencies
import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";

// Material UI Components

// Icons

// Components
import ModalContainer from "components/modals/ModalContainer/ModalContainer.component";
import PasswordResetRequest from "features/authentication/PasswordReset/PasswordResetRequest.component";

// Helpers/Thunks/Hooks
import { useQueryParams } from "hooks/useQueryParams.hook";
import { modalReducer } from "reducers/modal-reducer";

// Styles

const PasswordResetRequestModal = () => {
  const { updateQueryParams } = useQueryParams();
  const router = useRouter();
  const dispatch = useDispatch();
  const { modal } = router.query;
  const currentModal = useSelector((s) => s.modal.modal);

  const handleClose = () => {
    dispatch(modalReducer.actions.close());
  };

  useEffect(() => {
    if (modal === "passwordResetRequest") {
      dispatch(modalReducer.actions.show({ modal }));
      updateQueryParams({ modal: null });
    }
  }, [modal]);

  return (
    <div
      data-component="PasswordResetRequestModal"
      data-cy="password-reset-request-modal"
    >
      <ModalContainer
        open={currentModal === "passwordResetRequest"}
        handleClose={handleClose}
        contentChildren={<PasswordResetRequest modal />}
      />
    </div>
  );
};

export default PasswordResetRequestModal;
