import { makeStyles } from "@mui/styles";
import theme from "theme";

export const useStyles = makeStyles(() => ({
  root: {
    border: "solid 1px #dfe5ec",
    margin: "28px 0",
    display: "flex",
    padding: 16,
    alignItems: "flex-start",
    borderRadius: 4,
    flexDirection: "column",
  },
  icon: {
    width: 40,
    height: 40,
    flexGrow: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
    borderRadius: 64,
    backgroundColor: "#dbeeff",
    color: theme.palette.design.primary,
  },
  outerContainer: {
    width: "100%",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  leftSide: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    marginLeft: 8,
  },
  phoneInput: {
    marginTop: 14,
    paddingRight: 22,
  },
}));
