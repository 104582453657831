import { vinnV1Api } from "services/vinn-api-v1";

export const captchaSlice = vinnV1Api.injectEndpoints({
  endpoints: (builder) => ({
    validateRecaptchaToken: builder.mutation({
      query: (data) => ({
        url: "integrations/google/captcha",
        method: "POST",
        data: { token: data },
      }),
    }),
  }),
});

export const { useValidateRecaptchaTokenMutation } = captchaSlice;
