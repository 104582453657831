import { vinnApi } from "services/vinn-api";

export const authSlice = vinnApi.injectEndpoints({
  endpoints: (builder) => ({
    resendConfirmation: builder.mutation({
      query(data) {
        const {
          email,
          redirectUrl = `http://${window.location.host}/?modal=signIn`,
        } = data;
        return {
          url: "auth/confirmation",
          method: "POST",
          data: { email, redirect_url: redirectUrl },
        };
      },
    }),
  }),
});

export const { useResendConfirmationMutation } = authSlice;
