import PropTypes from "prop-types";

export const addressPropTypes = PropTypes.shape({
  city: PropTypes.string,
  country: PropTypes.string,
  google_place_id: PropTypes.string,
  id: PropTypes.number,
  latitude: PropTypes.string,
  longitude: PropTypes.string,
  postal_code: PropTypes.string,
  province: PropTypes.string,
  street: PropTypes.string,
});

export const addressDefaultProps = {
  city: "",
  country: "",
  google_place_id: "",
  id: -1,
  latitude: "",
  longitude: "",
  postal_code: "",
  province: "",
  street: "",
};
