import { makeStyles } from "@mui/styles";
import theme from "theme";

export const useStyles = makeStyles(() => ({
  subTitle: {
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
    },
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 0.1,
    textAlign: "left",
    color: theme.palette.design.subheader,
  },
}));
