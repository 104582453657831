// Dependencies
import React from "react";

// Material UI Components

// Icons

// Components

// Animations
import { paperPlaneAnimation } from "assets/externalImages";

// Helpers/Thunks/Hooks
import useLottieAnimations from "hooks/useLottieAnimations.hook";

// Styles
import { useStyles } from "./VerificationSent.styles";

const VerificationSent = () => {
  useLottieAnimations();
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      data-component="VerificationSent"
      data-cy="verification-modal"
    >
      <div className={classes.animationContainer}>
        <div className={classes.animation}>
          <lottie-player
            src={paperPlaneAnimation}
            background="transparent"
            speed="1"
            loop
            autoplay
          />
        </div>
      </div>
      <div className={classes.sentText}>
        Hang tight! We’ve sent you an email.
      </div>
      <div className={classes.verifyText}>
        Click “Confirm my account” in the email we sent you
      </div>
      <div className={classes.waitText}>
        Please wait a minute before trying again.
      </div>
    </div>
  );
};

export default VerificationSent;
