import React, { useEffect, useState, useRef } from "react";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useValidateRecaptchaTokenMutation } from "services/v1-slices/captcha-slice";

import SignUpLink from "features/authentication/SignIn/SignUpLink.component";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import LockIcon from "@mui/icons-material/Lock";
import AccountCircle from "@mui/icons-material/AccountCircle";

import { signInWithEmail } from "requests/auth-api";
import LoadingButton from "components/buttons/LoadingButton/LoadingButton.component";
import { homeRoute } from "routes";

import {
  localStorageGet,
  localStorageRemove,
} from "helpers/local-storage-helpers";

import usePageWidth from "hooks/usePageWidth.hook";
import useUser from "hooks/useUser.hook";
import { modalReducer } from "reducers/modal-reducer";

import { useStyles } from "./SignIn.styles";

const SignIn = ({ header, userType, modal, redirectTo }) => {
  const [focus, setFocus] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const router = useRouter();
  const { user, setUser } = useUser({
    redirectTo: modal ? null : redirectTo,
    redirectIfFound: true,
  });
  const classes = useStyles({ modal });
  const { isMobileWidth } = usePageWidth();
  const [snackbarShown, setSnackbarShown] = useState(false);

  const openPasswordResetRequest = () => {
    dispatch(modalReducer.actions.show({ modal: "passwordResetRequest" }));
  };

  useEffect(() => {
    if (router.query.accountConfirmationSuccess === "true" && !snackbarShown) {
      enqueueSnackbar("Account confirmation successful!", {
        variant: "success",
      });
      setSnackbarShown(true);
    }
  }, [router.query]);

  useEffect(() => {
    if (user && !modal) {
      router.replace(localStorageGet("location") || redirectTo || homeRoute);
    }
    if (modal && user) {
      if (redirectTo) {
        router.push(redirectTo);
      }
      dispatch(modalReducer.actions.close());
    }
  }, [user, modal]);

  const captchaFail = () => {
    setLoading(false);
    enqueueSnackbar("Invalid captcha response!", { variant: "error" });
  };

  const attemptSignIn = (e) => {
    setLoading(true);
    signInWithEmail({
      email: e.target.email.value,
      password: e.target.password.value,
    })
      .then((response) => {
        setUser(response.data);
        dispatch(modalReducer.actions.show({ modal: "signIn" }));
        setLoading(false);
        localStorageRemove("location");
      })
      .catch(() => {
        setLoading(false);
        enqueueSnackbar("Incorrect email or password!", { variant: "error" });
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    attemptSignIn(e);
  };

  const textFieldProps = {
    variant: "outlined",
    onFocus: () => {
      if (isMobileWidth) setFocus(true);
    },
    onBlur: () => setFocus(false),
  };

  return (
    <div
      className={clsx(classes.root, focus && classes.focusRoot)}
      data-component="SignIn"
    >
      <script src="https://www.google.com/recaptcha/api.js" async defer />
      <div data-cy="sign-in-page" className={classes.component}>
        <Container maxWidth="xs">
          <Typography
            variant="h1"
            data-cy="sign-in-header"
            className={classes.header}
          >
            {header}
          </Typography>
          <form
            onSubmit={handleSubmit}
            data-cy="sign-in-form"
            className={classes.formSignIn}
          >
            <div className={classes.container}>
              <TextField
                required
                {...textFieldProps}
                id="email"
                label="Email"
                margin="normal"
                className={classes.input}
                name="email"
                type="email"
                data-cy="sign-in-email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className={classes.container}>
              <TextField
                required
                {...textFieldProps}
                id="password"
                label="Password"
                margin="normal"
                className={classes.input}
                name="password"
                type="password"
                data-cy="sign-in-password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <LoadingButton
              className={classes.button}
              loading={loading}
              color="primary"
              size="large"
              variant="contained"
              fullWidth
              type="submit"
              data-cy="sign-in-button"
            >
              Sign In
            </LoadingButton>
            {userType !== "admin" && (
              <Button
                onClick={openPasswordResetRequest}
                data-cy="forgot-password"
                className={classes.passwordResetRequestButton}
              >
                Forgot password?
              </Button>
            )}
            {userType === "customer" && <SignUpLink />}
          </form>
        </Container>
      </div>
    </div>
  );
};

SignIn.propTypes = {
  header: PropTypes.string,
  userType: PropTypes.string,
  modal: PropTypes.bool,
  redirectTo: PropTypes.string,
};

SignIn.defaultProps = {
  header: "Sign in",
  userType: "customer",
  modal: false,
  redirectTo: undefined,
};

export default SignIn;
