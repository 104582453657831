// Dependencies
import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";

// Material UI Components

// Icons

// Components
import ModalContainer from "components/modals/ModalContainer/ModalContainer.component";
import SignIn from "features/authentication/SignIn/SignIn.component";

// Helpers/Thunks/Hooks
import useUser from "hooks/useUser.hook";
import { useQueryParams } from "hooks/useQueryParams.hook";
import { modalReducer } from "reducers/modal-reducer";

// Styles

const SignInModal = ({ redirectOnSignIn }) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { user: currentUser } = useUser();
  const { modal } = router.query;
  const currentModal = useSelector((s) => s.modal.modal);
  const { updateQueryParams } = useQueryParams();
  const handleClose = () => {
    dispatch(modalReducer.actions.close());
  };

  useEffect(() => {
    if (modal === "signIn") {
      if (!currentUser) {
        dispatch(modalReducer.actions.show({ modal }));
      }
      updateQueryParams({ modal: null, accountConfirmationSuccess: null });
    }
  }, [modal]);

  return (
    <div data-component="SignInModal" data-cy="sign-in-modal">
      <ModalContainer
        open={currentModal === "signIn"}
        handleClose={handleClose}
        contentChildren={<SignIn modal redirectTo={redirectOnSignIn} />}
      />
    </div>
  );
};

export default SignInModal;
