import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import { isMobile } from "react-device-detect";
import PropTypes from "prop-types";
import { enqueueSnackbar } from "actions/notifier-actions";

import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Button from "@mui/material/Button";

import LoadingButton from "components/buttons/LoadingButton/LoadingButton.component";
import ValidatedInput from "components/inputs/ValidatedInput/ValidatedInput.component";

import { resetPassword } from "requests/auth-api";
import { modalReducer } from "reducers/modal-reducer";

import { useStyles } from "./PasswordReset.styles";

const initialAuth = {
  password: "",
  passwordConfirmation: "",
};

const PasswordReset = ({ header, redirectRoute, modal }) => {
  const dispatch = useDispatch();
  const classes = useStyles({ modal });
  const router = useRouter();
  const [focus, setFocus] = useState(false);
  const [errors, setErrors] = useState();
  const [auth, setAuth] = useState(initialAuth);
  const [loading, setLoading] = useState(false);
  const openSignIn = () => {
    if (modal) {
      dispatch(modalReducer.actions.show({ modal: "signIn" }));
    } else {
      router.push(router.push(redirectRoute));
    }
  };

  const handleChange = (event) => {
    const updated = { ...auth };
    updated[event.target.name] = event.target.value;
    setAuth(updated);
  };

  const handleFocus = () => {
    if (isMobile) {
      setFocus(true);
    }
  };
  const handleBlur = () => {
    setFocus(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const resetPasswordToken = router.query?.reset_password_token;
    resetPassword({
      ...auth,
      resetPasswordToken,
    })
      .then(() => {
        setLoading(false);
        router.push(redirectRoute);
        enqueueSnackbar("Password successfully reset!", { variant: "success" });
        dispatch(modalReducer.actions.close());
      })
      .catch((error) => {
        const responseErrors = error.data.errors;
        setLoading(false);
        if (responseErrors[0])
          dispatch(enqueueSnackbar(responseErrors[0], { variant: "error" }));
        setErrors(responseErrors);
      });
  };

  const textFieldProps = {
    onChange: handleChange,
    variant: "outlined",
    onFocus: handleFocus,
    onBlur: handleBlur,
    className: classes.input,
    component: TextField,
    errors,
    type: "password",
    margin: "normal",
    required: true,
    autoComplete: "new-password",
  };

  return (
    <div
      data-cy="auth-page"
      className={focus ? classes.focusRoot : classes.root}
      data-component="PasswordReset"
    >
      <Container maxWidth="xs">
        <Typography variant="h1" data-cy="auth-header">
          {header}
        </Typography>
        <form
          onSubmit={handleSubmit}
          data-cy="reset-password-form"
          className={classes.formResetPassword}
        >
          <div className={classes.container}>
            <ValidatedInput
              {...textFieldProps}
              id="password"
              label="Password"
              name="password"
              value={auth.password}
              data-cy="auth-password"
            />
          </div>
          <div className={classes.container}>
            <ValidatedInput
              {...textFieldProps}
              id="password-confirmation"
              label="Confirm Password"
              name="passwordConfirmation"
              value={auth.passwordConfirmation}
            />
          </div>
          <LoadingButton
            className={classes.button}
            loading={loading}
            color="primary"
            size="large"
            variant="contained"
            endIcon={<ArrowForwardIosIcon />}
            type="submit"
            data-cy="auth-reset-button"
          >
            Reset
          </LoadingButton>
          <Button
            onClick={openSignIn}
            data-cy="sign-in"
            className={classes.signInButton}
          >
            Sign in?
          </Button>
        </form>
      </Container>
    </div>
  );
};

PasswordReset.propTypes = {
  header: PropTypes.string,
  redirectRoute: PropTypes.string,
  modal: PropTypes.bool,
};

PasswordReset.defaultProps = {
  header: "Password Reset",
  redirectRoute: "/sign-in",
  modal: false,
};

export default PasswordReset;
