import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: ({ isFindMyCar, isMobileWidth }) => ({
    [theme.breakpoints.down("sm")]: {
      alignSelf: "center",
      height: "calc(100vh - 82px)",
      overflow: "scroll",
    },
    width: isFindMyCar && !isMobileWidth ? "500px" : "auto",
  }),
  title: {
    [theme.breakpoints.down("xs")]: {
      padding: "0 16px",
    },
    flexGrow: 0,
    fontSize: 20,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.4,
    letterSpacing: "normal",
    textAlign: "left",

    padding: "24px 40px 0 40px",
  },
  titleBar: {
    [theme.breakpoints.down("xs")]: {
      width: "95%",
      background: "white",
      padding: "12px 0 0",
      position: "fixed",
      zIndex: 2,
    },
  },
  contentContainer: ({ isFindMyCar }) => ({
    [theme.breakpoints.down("xs")]: {
      marginTop: isFindMyCar ? 0 : 64,
    },
  }),
  content: ({ isFindMyCar }) => ({
    [theme.breakpoints.down("xs")]: {
      padding: isFindMyCar ? "0 16px 0 0" : "0 16px",
    },
    padding: isFindMyCar ? "0 40px 0 0" : "0 40px",
    overflowY: isFindMyCar ? "hidden" : "unset",
  }),
  checkTextContainer: {
    marginBottom: 16,
  },
  checkText: ({ isFindMyCar }) => ({
    [theme.breakpoints.up("md")]: {
      padding: "0 40px 8px 40px",
    },
    display: isFindMyCar ? "none" : "flex",
  }),
  icon: { marginRight: 8 },
  form: {
    marginTop: 10,
  },
  formContainer: {
    "& > p": {
      marginLeft: 10,
    },
    marginTop: 0,
  },
  formInput: {
    backgroundColor: "white",
    margin: "12px 0 0 0",
    width: "100%",
    "& > div": {
      borderRadius: 4,
    },
    "& label": {
      whiteSpace: "pre-wrap",
    },
  },
  subText: {
    marginLeft: 20,
    color: "darkgray",
  },
  legalText: ({ isFindMyCar }) => ({
    [theme.breakpoints.down("xs")]: {
      margin: isFindMyCar ? "16px 0 0 0" : "16px 16px 100px",
    },

    margin: isFindMyCar ? "unset" : "0 40px 20px",
    color: "darkgray",
    fontSize: "0.75rem",
  }),
  tryUs: ({ isFindMyCar }) => ({
    display: isFindMyCar ? "none" : "flex",
    height: 24,
    alignSelf: "stretch",
    flexGrow: 0,
    fontSize: 15,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.6,
    letterSpacing: 0.25,
    textAlign: "left",
  }),
  footerText: {
    display: "flex",
    justifyContent: "center",
    margin: 16,
  },
  dialogActions: ({ isFindMyCar }) => ({
    padding: isFindMyCar ? "20px 20px 20px 0" : "0 20px 20px 20px",
    justifyContent: isFindMyCar ? "flex-start" : "flex-end",
  }),
  dialogActionsMobile: ({ isFindMyCar }) => ({
    [theme.breakpoints.down("xs")]: {
      boxShadow: "unset",
    },
    zIndex: 1,
    position: "fixed",
    backgroundColor: "white",
    bottom: 0,
    height: 50,
    display: "flex",
    justifyContent: "center",
    width: "100vw",
    padding: "16px 0",
    boxShadow: "0px -3px 14px 2px rgba(98, 99, 109, 0.12)",
    left: isFindMyCar ? 0 : "unset",
  }),
  button: {
    height: 42,
  },
  callButton: ({ isVisible }) =>
    isVisible
      ? {
          marginRight: 16,
          width: "calc(50vw - 24px)",
          height: 42,
        }
      : {
          marginRight: "unset",
          width: "90vw",
          height: 42,
        },

  sendButtonMobile: {
    width: "calc(50vw - 24px)",
    height: 42,
  },
  closeButton: {
    marginRight: 16,
    textDecoration: "underline",
    height: 42,
  },
}));
