import PropTypes from "prop-types";
import { addressPropTypes } from "./address-props";
import { vehiclePhotoPropTypes } from "./vehicle-props";

export const listingPropTypes = PropTypes.shape({
  id: PropTypes.number,
  user: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    address: addressPropTypes,
  }),
  vehicle: PropTypes.shape({
    year: PropTypes.number,
    make: PropTypes.oneOfType([
      PropTypes.shape({
        name: PropTypes.string,
      }),
      PropTypes.string,
    ]),
    model: PropTypes.oneOfType([
      PropTypes.shape({
        name: PropTypes.string,
      }),
      PropTypes.string,
    ]),
    status: PropTypes.string,
    exteriorColour: PropTypes.oneOfType([
      PropTypes.shape({
        name: PropTypes.string,
      }),
      PropTypes.string,
    ]),
    interiorColour: PropTypes.oneOfType([
      PropTypes.shape({
        name: PropTypes.string,
      }),
      PropTypes.string,
    ]),
    photos: PropTypes.arrayOf(vehiclePhotoPropTypes),
    mainPhoto: vehiclePhotoPropTypes,
    photoCount: PropTypes.number,
  }),
  photos: PropTypes.arrayOf(vehiclePhotoPropTypes),
  kilometers: PropTypes.number,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  description: PropTypes.string,
  tax: PropTypes.number,
});

export const vdpListingPropType = PropTypes.shape({
  id: PropTypes.number,
  userId: PropTypes.number,
  seller: PropTypes.shape({
    sellerType: PropTypes.oneOf(["private", "dealership"]).isRequired,
  }),
  dealership: PropTypes.shape({}),
  vehicle: PropTypes.shape({
    year: PropTypes.number,
    make: PropTypes.string,
    model: PropTypes.string,
    driveType: PropTypes.string,
    fuelType: PropTypes.string,
    transmission: PropTypes.string,
    tax: PropTypes.number,
    dealershipId: PropTypes.number,
    exteriorColour: PropTypes.string,
    interiorColour: PropTypes.string,
    photos: PropTypes.arrayOf(vehiclePhotoPropTypes),
  }),
  status: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  description: PropTypes.string,
  kilometers: PropTypes.number,
});

export const inventoryListingPropType = PropTypes.shape({
  id: PropTypes.number,
  user: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    address: addressPropTypes,
  }),
  vehicle: PropTypes.shape({
    year: PropTypes.number,
    make: PropTypes.shape({
      name: PropTypes.string,
    }),
    model: PropTypes.shape({
      name: PropTypes.string,
    }),
    status: PropTypes.string,
    exteriorColour: PropTypes.shape({
      name: PropTypes.string,
    }),
    interiorColour: PropTypes.shape({
      name: PropTypes.string,
    }),
    photos: PropTypes.arrayOf(vehiclePhotoPropTypes),
    mainPhoto: vehiclePhotoPropTypes,
    photoCount: PropTypes.number,
  }),
  kilometers: PropTypes.number,
  price: PropTypes.string,
  tax: PropTypes.number,
});
