// Dependencies
import React from "react";

// Material UI Components
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

// Icons
import CloseIcon from "@mui/icons-material/Close";

// Components
import DialogActions from "@mui/material/DialogActions";

// Helpers/Thunks/Hooks
import usePageWidth from "hooks/usePageWidth.hook";

// Styles
import { useStyles } from "./ModalContainer.styles";

const ModalContainer = ({
  open,
  handleClose,
  children,
  contentChildren,
  actionsChildren,
}) => {
  const classes = useStyles();
  const { isDesktopWidth } = usePageWidth();

  const dialogProps = {
    fullScreen: !isDesktopWidth,
    onClose: handleClose,
    maxWidth: "xs",
    fullWidth: true,
  };

  return (
    <div
      className={classes.root}
      data-component="ModalContainer"
      data-cy="modal-container"
    >
      <Dialog {...dialogProps} open={open}>
        {children || (
          <div>
            <DialogTitle className={classes.dialogTitle}>
              <CloseIcon
                onClick={handleClose}
                data-cy="close-button"
                className={classes.icon}
              />
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              {contentChildren}
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {actionsChildren}
            </DialogActions>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default ModalContainer;
