// Dependencies
import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";

// Material UI Components

// Icons

// Components
import ModalContainer from "components/modals/ModalContainer/ModalContainer.component";
import SignUp from "features/authentication/SignUp/SignUp.component";

// Helpers/Thunks/Hooks
import useUser from "hooks/useUser.hook";
import { useQueryParams } from "hooks/useQueryParams.hook";
import { modalReducer } from "reducers/modal-reducer";

// Styles

const SignUpModal = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { user: currentUser } = useUser();
  const { modal } = router.query;
  const currentModal = useSelector((s) => s.modal.modal);
  const { updateQueryParams, getNewUrl } = useQueryParams();

  const handleClose = () => {
    dispatch(modalReducer.actions.close());
  };

  useEffect(() => {
    if (modal === "signUp") {
      if (!currentUser) {
        dispatch(modalReducer.actions.show({ modal: "signUp" }));
      }
      updateQueryParams({ modal: null });
    }
  }, [modal]);

  return (
    <div data-component="SignUpModal" data-cy="sign-up-modal">
      <ModalContainer
        open={currentModal === "signUp"}
        handleClose={handleClose}
        contentChildren={
          <SignUp
            modal
            currentVDP={router.asPath}
            confirmSuccessUrl={getNewUrl({ modal: "signIn" })}
          />
        }
      />
    </div>
  );
};

export default SignUpModal;
