import PropTypes from "prop-types";
import { addressPropTypes } from "./address-props";

export const vehiclePropTypes = PropTypes.shape({
  full_name: PropTypes.string,
  price: PropTypes.string,
  status: PropTypes.string,
  vin: PropTypes.string,
  stock_number: PropTypes.string,
});

export const vehiclePhotoPropTypes = PropTypes.shape({
  id: PropTypes.number,
  path: PropTypes.string,
  originalUrl: PropTypes.string,
  position: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

export const vehiclePhotosPropType = PropTypes.arrayOf(vehiclePhotoPropTypes);

export const inventoryVehiclePropType = PropTypes.shape({
  id: PropTypes.number,
  photos: PropTypes.arrayOf(vehiclePhotoPropTypes),
  mainPhoto: vehiclePhotoPropTypes,
  photoCount: PropTypes.number,
  dealership: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    address: addressPropTypes,
  }),
  currentListing: PropTypes.shape({
    id: PropTypes.number,
    price: PropTypes.string,
    status: PropTypes.string,
    kilometers: PropTypes.number,
  }),
  year: PropTypes.number,
  make: PropTypes.string,
  model: PropTypes.shape({
    id: PropTypes.number.isRequired,
    make: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    name: PropTypes.string,
  }),
  status: PropTypes.string,
  kilometers: PropTypes.number,
  price: PropTypes.string,
  tax: PropTypes.number,
});
