import { vinnV1Api } from "services/vinn-api-v1";

export const inboundLeadSlice = vinnV1Api.injectEndpoints({
  endpoints: (builder) => ({
    inboundLead: builder.mutation({
      query: (data) => ({
        url: "/pages/inbound_leads",
        method: "POST",
        data,
      }),
    }),
  }),
});

export const { useInboundLeadMutation } = inboundLeadSlice;
