import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    "@media only screen and (max-height: 800px)": {
      padding: props.modal ? "unset" : "50px 0",
    },

    display: "flex",
    height: props.modal ? "unset" : "100vh",
    justifyContent: "center",
    flexDirection: "column",
    marginBottom: 24,
  }),
  component: (props) => ({
    padding: props.modal ? "unset" : "50px 0px",
  }),
  focusRoot: {
    height: "unset",
  },
  header: {
    marginBottom: 20,
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 16,
  },
  submit: {
    marginTop: "15px",
    height: "55px",
  },
  input: {
    width: "100%",
    margin: "unset",
  },
  button: {},
  switchButton: {
    ...theme.design.question,
    padding: "unset",
  },
  text: {
    ...theme.design.question,
    color: theme.palette.design.subheader,
    marginTop: "10px",
    textAlign: "center",
    display: "flex",
    justifyContent: "left",
    padding: "unset",
    alignItems: "center",
  },
  lineWord: {
    width: "100%",
    textAlign: "center",
    borderBottom: "1px solid rgba(0,0,0,0.25)",
    lineHeight: "0.1em",
    margin: "25px 0 20px",
    "& span": {
      color: "rgba(0,0,0,0.5)",
      background: "rgba(255,255,255,1)",
      padding: "0 10px",
    },
  },
  resendButton: {
    ...theme.design.button.normal,
  },
}));
