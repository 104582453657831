// Dependencies
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import PropTypes from "prop-types";

// Material UI Components
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import PhoneIcon from "@mui/icons-material/Phone";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// Components
import BotProtectedForm from "components/forms/BotProtectedForm/BotProtectedForm.component";
import PhoneInput from "components/inputs/PhoneInput/PhoneInput.component";
import ValidatedInput from "components/inputs/ValidatedInput/ValidatedInput.component";

// Icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// Helpers/Thunks/Hooks
import {
  capitalizeFirstLetter,
  formatPhoneNumberString,
} from "helpers/string-format-helpers";
import usePageWidth from "hooks/usePageWidth.hook";
import { vdpListingPropType } from "custom-prop-types/listing-props";
import useOnScreen from "hooks/useOnScreen.hook";
import { helpReducer } from "reducers/help-reducer";
import { modalReducer } from "reducers/modal-reducer";
import { useGetListingQuery } from "services/v1-slices/listings-slice";
import { useInboundLeadMutation } from "services/v1-slices/inbound-lead-slice";
import { enqueueSnackbar } from "actions/notifier-actions";

// Styles
import { useStyles } from "./TalkToVEForm.styles";

const TalkToVEForm = ({
  handleClose,
  title,
  email,
  points,
  subText,
  placeholderText,
}) => {
  const dispatch = useDispatch();
  const { isMobileWidth } = usePageWidth();
  const ref = useRef();
  const router = useRouter();
  const queryParamsString = router.asPath.split("?")[1];
  const modal = useSelector((state) => state.modal.modal);
  const modalVariant = useSelector((state) => state.modal.variant);
  const isFindMyCar = modalVariant === "findMyCar";
  const isPrivateSale = modalVariant === "privateSale";

  const onScreen = useOnScreen(ref);
  const isVisible = isMobileWidth ? onScreen : true;
  const classes = useStyles({ isFindMyCar, isMobileWidth, isVisible });
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [message, setMessage] = useState("");
  const errors = useSelector((state) => state.help.errors);
  const loading = useSelector((state) => state.help.loading);
  const [messagePlaceholder, setMessagePlaceholder] = useState(placeholderText);
  const [createInboundLead] = useInboundLeadMutation();

  const filled =
    phone.length > 0 && name.length > 0 && (!email || emailValue.length > 0);

  const { id: listingIdParam } = router.query;
  const { data: listing } = useGetListingQuery(listingIdParam, {
    skip: listingIdParam === "new",
  });

  const resetFormValues = () => {
    setName("");
    setPhone("");
    setEmailValue("");
    setMessage("");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(helpReducer.actions.setLoading(true));
    createInboundLead({
      name,
      email: emailValue,
      phone,
      listingId: listing?.id,
      origin: "veForm",
      message,
      utms: queryParamsString,
    }).then((res) => {
      if (res.data) {
        dispatch(helpReducer.actions.resetState());
        dispatch(
          enqueueSnackbar("We have received your message", {
            variant: "success",
            id: "snackbar-lead-success",
          }),
        );
        dispatch(modalReducer.actions.close());
        handleClose();
        dispatch(
          modalReducer.actions.show({
            modal: "sentConfirmation",
            variant: modalVariant,
          }),
        );
      }
      if (res.error) {
        dispatch(helpReducer.actions.setErrors(res.error.data));
        dispatch(
          enqueueSnackbar("Could not send inquiry", { variant: "error" }),
        );
      }
    });
  };

  const sharedInputProps = (field) => ({
    name: field,
    id: `help${capitalizeFirstLetter(field)}`,
    required: true,
    variant: "outlined",
    errors,
    className: classes.formInput,
    "data-cy": `help-${field}`,
  });

  const renderMessageField = () => (
    <ValidatedInput
      {...sharedInputProps("message")}
      value={message}
      onChange={(event) => setMessage(event.target.value)}
      required={false}
      label={message === "" ? messagePlaceholder : "Message"}
      onFocus={() => setMessagePlaceholder("Message")}
      onBlur={() => setMessagePlaceholder(placeholderText)}
      component={TextField}
      multiline
      minRows={4}
    />
  );

  const renderPhoneField = () => (
    <>
      <ValidatedInput
        {...sharedInputProps("phone")}
        value={phone}
        onChange={(val) => setPhone(val)}
        label="Phone number"
        component={PhoneInput}
      />
      {!isFindMyCar && (
        <Typography className={classes.subText} variant="caption">
          We&apos;ll contact you at this number
        </Typography>
      )}
    </>
  );

  const renderEmailField = () => {
    if (!email) return null;
    return (
      <ValidatedInput
        {...sharedInputProps("email")}
        value={emailValue}
        onChange={(event) => setEmailValue(event.target.value)}
        label="E-mail"
        className={classes.formInput}
        errors={errors}
        component={TextField}
      />
    );
  };

  const renderNameField = () => (
    <ValidatedInput
      {...sharedInputProps("name")}
      value={name}
      onChange={(event) => setName(event.target.value)}
      label="Name"
      className={classes.formInput}
      component={TextField}
    />
  );

  const renderCloseModalButton = () => (
    <Button
      className={classes.button}
      disabled={loading}
      variant="contained"
      color="primary"
      onClick={handleClose}
      autoFocus
      data-cy="close-modal-button"
    >
      Close
    </Button>
  );

  const renderSubmitButton = (className) => (
    <Button
      className={className}
      disabled={loading || !filled}
      type="submit"
      variant="contained"
      color="primary"
      autoFocus
      data-cy="send-button"
    >
      Send message
    </Button>
  );

  const renderMobileDialogActions = () => (
    <DialogActions className={classes.dialogActionsMobile}>
      {modal !== "sentConfirmation" && process.env.VINN_INBOUND_PHONE_NUMBER ? (
        <div>
          <Button
            disabled={loading}
            variant="outlined"
            color="primary"
            className={classes.callButton}
            startIcon={<PhoneIcon />}
            href={`tel:${process.env.VINN_INBOUND_PHONE_NUMBER}`}
          >
            Call us
          </Button>
          {isVisible && renderSubmitButton(classes.sendButtonMobile)}
        </div>
      ) : (
        !isFindMyCar && renderCloseModalButton()
      )}
    </DialogActions>
  );

  const renderDialogActions = () => (
    <DialogActions className={classes.dialogActions}>
      {modal !== "sentConfirmation" ? (
        <div>
          {!isFindMyCar && (
            <Button
              disabled={loading}
              variant="text"
              color="primary"
              className={classes.closeButton}
              onClick={handleClose}
              data-cy="close-modal-button"
            >
              Close
            </Button>
          )}
          {renderSubmitButton(classes.button)}
        </div>
      ) : (
        !isFindMyCar && renderCloseModalButton()
      )}
    </DialogActions>
  );

  const iconProps = {
    color: "primary",
    className: classes.icon,
  };

  useEffect(() => {
    if (modal !== "sentConfirmation") resetFormValues();
    if (isPrivateSale)
      setMessagePlaceholder("I need help determining a price for my vehicle.");
  }, [modal, isPrivateSale]);

  return (
    <div
      className={classes.root}
      data-component="TalkToVEForm"
      id="talk-to-ve-form"
      data-cy="talk-to-ve-form"
      ref={ref}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        className={classes.titleBar}
      >
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          data-cy="back-arrow"
          size="large"
        >
          {isMobileWidth && isFindMyCar ? <ArrowBackIcon /> : <CloseIcon />}
        </IconButton>
      </DialogTitle>
      <div className={classes.contentContainer}>
        <div className={classes.title}>
          {isPrivateSale
            ? "Connect with a listing agent who can help. We work 100% for the customer and it’s free!"
            : title}
        </div>
        <BotProtectedForm onSubmit={handleSubmit} id="talk-to-ve-form">
          <DialogContent className={classes.content}>
            <div className={classes.checkTextContainer}>
              {points.map((point) => (
                <div key={point} className={classes.checkText}>
                  <CheckBoxIcon {...iconProps} />
                  {point}
                </div>
              ))}
            </div>
            <Typography className={classes.tryUs}>{subText}</Typography>
            <div className={classes.form}>
              {isFindMyCar ? (
                <>
                  {renderNameField()}
                  {renderEmailField()}
                  {renderPhoneField()}
                  {renderMessageField()}
                </>
              ) : (
                <>
                  {renderMessageField()}
                  {renderNameField()}
                  {renderEmailField()}
                  {renderPhoneField()}
                </>
              )}
            </div>
            {!(isMobileWidth || isFindMyCar) &&
              process.env.VINN_INBOUND_PHONE_NUMBER && (
                <Typography
                  className={classes.footerText}
                  color="primary"
                  variant="caption"
                >
                  Or give us a call{" "}
                  {formatPhoneNumberString(
                    process.env.VINN_INBOUND_PHONE_NUMBER,
                  )}
                </Typography>
              )}
          </DialogContent>
          {isMobileWidth ? renderMobileDialogActions() : renderDialogActions()}
        </BotProtectedForm>
        <div className={classes.legalText} variant="caption">
          By entering your email address, you consent to receive updates,
          newsletters and promotional communications from VINN Automotive
          Technologies Limited.
        </div>
      </div>
    </div>
  );
};

export default TalkToVEForm;

TalkToVEForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  email: PropTypes.bool,
  listing: vdpListingPropType,
  points: PropTypes.arrayOf(PropTypes.string),
  subText: PropTypes.string,
  placeholderText: PropTypes.string,
};

TalkToVEForm.defaultProps = {
  title:
    "Connect with a vehicle expert who can help. We work 100% for the customer and it's free!",
  email: true,
  points: [],
  subText: "How can we help you?",
  placeholderText: "I'm looking for a reliable car. Is this a good option...",
  listing: null,
};
