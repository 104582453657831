// Dependencies
import React from "react";

// Material UI Components
import Button from "@mui/material/Button";

// Icons

// Components

// Helpers/Thunks/Hooks

// Styles
import { useDispatch } from "react-redux";
import { modalReducer } from "reducers/modal-reducer";
import { useStyles } from "./SignUpLink.styles";

const SignUpLink = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <div className={classes.text}>
      {"Don't have an account? "}
      <Button
        className={classes.switchButton}
        variant="text"
        color="primary"
        onClick={() => dispatch(modalReducer.actions.show({ modal: "signUp" }))}
      >
        Sign up
      </Button>
    </div>
  );
};

export default SignUpLink;
