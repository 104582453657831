import { useEffect } from "react";

const useLottieAnimations = () => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src =
      "https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js";
    script.id = "lottie";
    script.async = true;

    if (!document.scripts.namedItem("lottie"))
      document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
};

export default useLottieAnimations;
