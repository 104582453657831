import { makeStyles } from "@mui/styles";
import theme from "theme";

export const useStyles = makeStyles(() => ({
  actions: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse;",
    },
    padding: "18px 16px",
  },
  dialogTitle: {
    [theme.breakpoints.down("xs")]: {
      padding: "unset",
      marginBottom: "16px",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  dialogTitleText: {
    fontSize: 20,
    lineHeight: 1.4,
    color: theme.palette.design.header,
  },
  dialogContentRoot: {
    [theme.breakpoints.down("xs")]: {
      height: "calc(100% - 74px)",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  dialogContent: {
    [theme.breakpoints.down("xs")]: {
      padding: "unset",
    },
    alignSelf: "stretch",
    flexGrow: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "stretch",
  },
  dialogContentText: {
    alignSelf: "stretch",
    flexGrow: 0,
    fontSize: 14,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: 0.25,
    textAlign: "left",
    color: "#1b1b1f",
  },
  lottie: {
    width: 180,
    height: 180,
  },
  button: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "6px 0 !important",
    },
    width: 88,
    height: 42,
    alignSelf: "center",
    flexGrow: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
  },
}));
