import { vinnV1Api } from "services/vinn-api-v1";

export const listingsSlice = vinnV1Api.injectEndpoints({
  endpoints: (builder) => ({
    getListing: builder.query({
      query: ({ listingId, ...params }) => ({
        url: `/listings/${listingId}`,
        params,
      }),
      providesTags: (_result, _error, { id }) => [{ type: "Listings", id }],
    }),
    updateListing: builder.mutation({
      query(data) {
        return {
          url: `/listings/${data.id}`,
          method: "PATCH",
          data,
        };
      },
      // If listing is updated, we invalidate the cached listing so that it will be re-fetched
      // from the server the next time a useGetListingQuery is called.
      invalidatesTags: (_result, _error, { id, conversationId = undefined }) =>
        conversationId
          ? [
              { type: "Listings", id },
              { type: "Conversations", id: conversationId },
              { type: "Listings" },
            ]
          : [{ type: "Listings", id }, { type: "Listings" }],
    }),
    getListings: builder.query({
      query: ({ ...params }) => ({
        url: "/listings",
        params,
      }),
      providesTags: () => [{ type: "Listings" }],
    }),
    getListingsCount: builder.query({
      query: ({ ...params }) => ({
        url: "/listings/count",
        params,
      }),
      providesTags: () => [{ type: "Listings" }],
    }),
    createViewCount: builder.mutation({
      query({ listingId, ...params }) {
        return {
          url: `listings/${listingId}/views`,
          method: "POST",
          params,
        };
      },
      invalidatesTags: () => [{ type: "Listings" }],
    }),
    updateViewCount: builder.mutation({
      query({ listingId, viewId, ...params }) {
        return {
          url: `listings/${listingId}/views/${viewId}`,
          method: "PATCH",
          params,
        };
      },
    }),
  }),
});

export const {
  useGetListingQuery,
  useGetListingsQuery,
  useGetListingsCountQuery,
  useLazyGetListingQuery,
  useUpdateListingMutation,
  useCreateViewCountMutation,
  useUpdateViewCountMutation,
} = listingsSlice;

// export endpoints for use in SSR
export const { getListings } = vinnV1Api.endpoints;
