import { useRouter } from "next/router";
import cloneDeep from "lodash/cloneDeep";
import { ISSERVER } from "helpers/server-helpers";

export const useQueryParams = () => {
  const router = useRouter();

  const getNewUrl = (params) => {
    const url = !ISSERVER
      ? window.location.origin + window.location.pathname
      : "";
    const data = { ...router.query, ...params };
    const newParams = Object.keys(data)
      .filter((key) => data[key])
      .filter((key) => !url.includes(data[key]));
    const newUrl = newParams.length
      ? `${url}?${newParams
          .map((key) => [key, data[key]].map(encodeURIComponent).join("="))
          .join("&")}`
      : url;
    return newUrl;
  };

  const updateQueryParams = (params) => {
    const queryCopy = cloneDeep(router.query);
    Object.keys(params).forEach((key) => {
      if (params[key] === null || params[key] === "") {
        delete queryCopy[key];
      } else {
        queryCopy[key] = params[key];
      }
    });
    router.replace({ query: queryCopy }, undefined, { shallow: true });
  };

  return { getNewUrl, updateQueryParams };
};
