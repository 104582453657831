// Dependencies
import React from "react";

// Material UI Components

// Icons

// Components
import SignUpModal from "components/modals/SignUpModal.component";
import SignInModal from "components/modals/SignInModal.component";
import PasswordResetRequestModal from "components/modals/PasswordResetRequestModal.component";
import PasswordResetModal from "components/modals/PasswordResetModal.component";
import NotificationSettingsModal from "components/modals/NotificationSettingsModal.component";
import TalkToVEModal from "components/modals/TalkToVEModal.component";
import SentConfirmationModal from "components/modals/SentConfirmationModal.component";
import VerificationSentModal from "components/modals/VerificationSentModal.component";

// Styles

const GlobalModals = ({ redirectOnSignIn }) => (
  <div data-component="GlobalModals">
    <SignUpModal />
    <SignInModal redirectOnSignIn={redirectOnSignIn} />
    <NotificationSettingsModal />
    <PasswordResetRequestModal />
    <PasswordResetModal />
    <TalkToVEModal />
    <SentConfirmationModal />
    <VerificationSentModal />
  </div>
);

export default GlobalModals;
