import React, { useState } from "react";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import LoadingButton from "components/buttons/LoadingButton/LoadingButton.component";

import { requestResetPassword } from "requests/auth-api";
import { modalReducer } from "reducers/modal-reducer";
import { useQueryParams } from "hooks/useQueryParams.hook";

// Styles
import { useStyles } from "./PasswordReset.styles";

const PasswordResetRequest = ({ header, modal }) => {
  const classes = useStyles({ modal });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [auth, setAuth] = useState({
    email: "",
  });
  const { enqueueSnackbar } = useSnackbar();
  const { getNewUrl } = useQueryParams();

  const handleChange = (event) => {
    const updated = { ...auth };
    updated[event.target.name] = event.target.value;
    setAuth(updated);
  };

  const forgotPassword = (event) => {
    event.preventDefault();
    setLoading(true);
    requestResetPassword({
      email: auth.email,
      redirectUrl: getNewUrl({ modal: "passwordReset" }),
    })
      .then(() => {
        setLoading(false);
        enqueueSnackbar("Password email sent, please check your inbox", {
          variant: "success",
        });
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar(error.data.errors[0], { variant: "error" });
      });
  };

  const openSignIn = () => {
    dispatch(modalReducer.actions.show({ modal: "signIn" }));
  };

  return (
    <div data-cy="auth-page" className={classes.root}>
      <Container maxWidth="xs">
        <Typography variant="h1" data-cy="auth-header">
          {header}
        </Typography>
        <form
          onSubmit={forgotPassword}
          data-cy="reset-password-form"
          className={classes.formResetPassword}
        >
          <div className={classes.container}>
            <TextField
              required
              id="email"
              label="Email"
              margin="normal"
              className={classes.input}
              name="email"
              data-cy="auth-email"
              onChange={handleChange}
              variant="outlined"
            />
          </div>
          <LoadingButton
            className={classes.button}
            loading={loading}
            color="primary"
            size="large"
            variant="contained"
            endIcon={<ArrowForwardIosIcon />}
            type="submit"
            data-cy="auth-reset-button"
          >
            Reset
          </LoadingButton>
          <Button
            onClick={openSignIn}
            data-cy="sign-in"
            className={classes.signInButton}
          >
            Sign in?
          </Button>
        </form>
      </Container>
    </div>
  );
};

PasswordResetRequest.propTypes = {
  header: PropTypes.string,
};

PasswordResetRequest.defaultProps = {
  header: "Password Reset",
};

export default PasswordResetRequest;
