// Dependencies
import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";

// Material UI Components

// Icons

// Components
import ModalContainer from "components/modals/ModalContainer/ModalContainer.component";
import TalkToVEForm from "components/forms/TalkToVEForm/TalkToVEForm.component";

// Helpers/Thunks/Hooks
import { useQueryParams } from "hooks/useQueryParams.hook";
import { modalReducer } from "reducers/modal-reducer";

// Styles

const TalkToVEModal = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { modal } = router.query;
  const currentModal = useSelector((s) => s.modal.modal);
  const { updateQueryParams } = useQueryParams();

  const handleClose = () => {
    dispatch(modalReducer.actions.close());
  };

  useEffect(() => {
    if (modal === "talkToVE") {
      dispatch(modalReducer.actions.show({ modal, variant: "default" }));
      updateQueryParams({ modal: null });
    }
  }, [modal]);

  return (
    <div data-component="TalkToVEModal" data-cy="talk-to-ve-modal">
      <ModalContainer
        open={currentModal === "talkToVE"}
        handleClose={handleClose}
      >
        <TalkToVEForm handleClose={handleClose} />
      </ModalContainer>
    </div>
  );
};

export default TalkToVEModal;
