// Dependencies
import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

// Material UI Components
import Button from "@mui/material/Button";

// Icons

// Components
import ModalContainer from "components/modals/ModalContainer/ModalContainer.component";
import VerificationSent from "components/content/VerificationSent/VerificationSent.component";

// Helpers/Thunks/Hooks
import { useQueryParams } from "hooks/useQueryParams.hook";
import { useResendConfirmationMutation } from "services/slices/auth-slice";
import { pluralizeString } from "helpers/string-format-helpers";
import { modalReducer } from "reducers/modal-reducer";

// Styles

const VerificationSentModal = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { modal, email } = router.query;
  const currentModal = useSelector((s) => s.modal.modal);
  const [countdown, setCountdown] = useState(60);
  const [resendConfirmation] = useResendConfirmationMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { getNewUrl, updateQueryParams } = useQueryParams();
  const handleClose = () => {
    setCountdown("");
    dispatch(modalReducer.actions.show({ modal: "signIn" }));
  };

  const timer = () => {
    if (countdown > 0) {
      setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    }
  };

  const handleResendVerification = () => {
    const redirectUrl = getNewUrl({ modal: "signIn" });
    resendConfirmation({ email, redirectUrl })
      .then(() => {
        enqueueSnackbar("Verification email sent", { variant: "success" });
        setCountdown(60);
      })
      .catch((error) => {
        enqueueSnackbar(`Something went wrong =>${error}`, {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    if (modal === "verificationSent") {
      dispatch(modalReducer.actions.show({ modal }));
      updateQueryParams({ modal: null });
    }
  }, [modal]);

  useEffect(() => {
    if (currentModal === "verificationSent") timer();
  }, [countdown, currentModal]);

  return (
    <div
      data-component="VerificationSentModal"
      data-cy="verification-sent-modal"
    >
      <ModalContainer
        open={currentModal === "verificationSent"}
        handleClose={handleClose}
        contentChildren={<VerificationSent />}
        actionsChildren={
          <Button
            disabled={countdown > 0}
            variant="contained"
            color="primary"
            onClick={handleResendVerification}
            fullWidth
            data-cy="resend-button"
          >
            {countdown === 0
              ? "Resend email"
              : `Resend email in ${countdown} ${pluralizeString(
                  "second",
                  countdown,
                )}`}
          </Button>
        }
      />
    </div>
  );
};

export default VerificationSentModal;
