import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const VinnLogo = (props) => (
  <SvgIcon {...props} viewBox="0 0 1490 480">
    <path d="M598.495 11.1794H504.603V469.702H598.495V11.1794Z" />
    <path d="M6 11.1794H77.6093C95.5116 11.1794 111.509 22.9868 117.223 40.3044L190.927 262.481C196.069 278.421 201.783 296.526 207.687 316.205C213.781 336.081 221.399 357.335 221.399 357.335C221.399 357.335 229.588 334.31 236.064 311.286C242.539 288.064 247.491 271.927 250.919 262.481L324.052 40.5012C329.956 22.9868 345.954 11.1794 363.856 11.1794H431.656L271.297 469.702H166.549L6 11.1794Z" />
    <path d="M1125 11H1181.18C1202.89 11 1223.27 22.6107 1234.7 41.6994C1279.84 116.874 1391.82 304.022 1393.34 306.777V11H1484.57V469.523H1428.39C1406.49 469.523 1386.3 457.912 1374.87 438.823C1329.73 363.649 1217.56 176.108 1216.42 173.943V469.523H1125V11V11Z" />
    <path d="M682 11H738.183C759.894 11 780.272 22.6107 791.699 41.6994C836.836 116.874 948.821 304.022 950.344 306.777V11H1041.57V469.523H985.387C963.676 469.523 943.298 457.912 931.871 438.823C886.734 363.649 774.559 176.108 773.416 173.943V469.523H682V11V11Z" />
  </SvgIcon>
);

export default VinnLogo;
