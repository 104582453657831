// External dependencies
import React from "react";
import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

// Styles
import { useStyles } from "./LoadingButton.styles";

const Spinner = () => {
  const classes = useStyles();
  return <CircularProgress className={classes.spinner} size={20} />;
};

const LoadingButton = (props) => {
  const { children, loading, disabled, ...rest } = props;
  return (
    <Button {...rest} disabled={loading || disabled}>
      {!loading && children}
      {loading && <Spinner {...rest} />}
    </Button>
  );
};

LoadingButton.propTypes = {
  children: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

LoadingButton.defaultProps = {
  children: "",
  loading: false,
  disabled: false,
};

export default LoadingButton;
