// Dependencies
import React from "react";
import PropTypes from "prop-types";

// Material UI Components
import Switch from "@mui/material/Switch";

// Icons
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import VinnLogo from "custom-icons/VinnLogo.icon";

// Components
import PhoneInput from "components/inputs/PhoneInput/PhoneInput.component";
import ValidatedInput from "components/inputs/ValidatedInput/ValidatedInput.component";

// Helpers/Thunks/Hooks

// Styles
import { useStyles } from "./NotificationSettingsCard.styles";

/**
 * @param {string} type  email, text, or dashboard
 */
const NotificationSettingsCard = ({
  type,
  formFields,
  handleChange,
  errors,
}) => {
  const classes = useStyles();

  const handleType = () => {
    switch (type) {
      case "email":
        return (
          <div className={classes.container}>
            <div className={classes.leftSide}>
              <div className={classes.icon}>
                <MailOutlineIcon />
              </div>
              <div className={classes.title}>Email</div>
            </div>
            <Switch
              onChange={handleChange("offerEmailNotifications")}
              checked={formFields.offerEmailNotifications}
              data-cy="email-switch"
            />
          </div>
        );
      case "text":
        return (
          <div className={classes.outerContainer}>
            <div className={classes.container}>
              <div className={classes.leftSide}>
                <div className={classes.icon}>
                  <ChatOutlinedIcon />
                </div>
                <div className={classes.title}>Text Message</div>
              </div>
              <Switch
                onChange={handleChange("offerTextNotifications")}
                checked={formFields.offerTextNotifications}
                data-cy="text-switch"
              />
            </div>
            <div>
              {formFields?.offerTextNotifications && (
                <ValidatedInput
                  className={classes.phoneInput}
                  value={formFields.phone}
                  variant="outlined"
                  type="phone"
                  label="Phone"
                  name="phone"
                  data-cy="phone-input"
                  component={PhoneInput}
                  onChange={(value, _, e) => handleChange("phone")(e, value)}
                  errors={errors}
                />
              )}
            </div>
          </div>
        );
      case "dashboard":
        return (
          <div className={classes.container}>
            <div className={classes.leftSide}>
              <div className={classes.icon}>
                <VinnLogo />
              </div>
              <div className={classes.title}>Dashboard</div>
            </div>
            <Switch disabled defaultChecked data-cy="dashboard-switch" />
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className={classes.root} data-component="NotificationCard">
      {handleType()}
    </div>
  );
};

NotificationSettingsCard.propTypes = {
  type: PropTypes.string.isRequired,
  formFields: PropTypes.shape({
    offerEmailNotifications: PropTypes.bool,
    offerTextNotifications: PropTypes.bool,
    phone: PropTypes.string,
  }),
  handleChange: PropTypes.func,
  errors: PropTypes.shape({
    phone: PropTypes.string,
  }),
};

NotificationSettingsCard.defaultProps = {
  errors: {},
  formFields: {},
  handleChange: () => {},
};

export default NotificationSettingsCard;
