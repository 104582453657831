import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    position: "static",
    boxShadow: "none",
    borderRadius: 0,
    borderBottom: (props) => (props.noBorder ? "none" : theme.shape.border),
  },

  toolBarContainer: {
    width: "100%",
  },
  toolBar: {
    height: 65,
    display: "flex",
    margin: "0 auto",
    maxWidth: "calc(100vw - 70px)",
    paddingLeft: 0,
    paddingRight: 0,
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "calc(100vw - 30px)",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    "& > p:first-child": {
      gap: 20,
      display: "flex",
      "& > a": {
        lineHeight: "65px",
        alignItems: "center",
      },
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  button: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    ...theme.design.headerButton,
  },
  noDecoration: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  icon: {
    display: "inline-flex",
    verticalAlign: "middle",
  },
  white: {
    color: "white",
  },
  black: {
    color: "black",
  },
}));
