// Dependencies
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import camelCase from "lodash/camelCase";
import PropTypes from "prop-types";

// Material UI Components

// Icons

// Components
import NotificationSettingsCard from "features/main/components/PrivateSaleOffer/NotificationCard/NotificationSettingsCard.component";
import LoadingButton from "components/buttons/LoadingButton/LoadingButton.component";

// Helpers/Thunks/Hooks
import {
  useUpdateUserSettingsMutation,
  useGetUserQuery,
} from "services/v1-slices/users-slice";
import { updateAccount } from "requests/auth-api";
import { enqueueSnackbar } from "actions/notifier-actions";
import { deepChangeObjectKeysCase } from "helpers/object-helpers";
import { useQueryParams } from "hooks/useQueryParams.hook";
import { modalReducer } from "reducers/modal-reducer";

// Styles
import { useStyles } from "./NotificationSettings.styles";

const NotificationSettings = ({ currentUser }) => {
  const { updateQueryParams } = useQueryParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data, isSuccess } = useGetUserQuery({
    userId: currentUser.id,
    view: "settings",
  });
  const userSettings = data?.setting;
  const [formFields, setFormFields] = useState({});
  const [loading, setLoading] = useState(false);
  const [updateUserSettings] = useUpdateUserSettingsMutation();
  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);

  const handleChange =
    (key, parser = (value) => value) =>
    (event, value = event.target.value) => {
      const updates = { [key]: parser(value) };
      const clonedFormFields = cloneDeep(formFields);
      Object.keys(updates).forEach((x) => {
        set(clonedFormFields, x, updates[x]);
      });
      setFormFields(clonedFormFields);
    };

  useEffect(() => {
    setFormFields({
      phone: currentUser?.phone ?? "",
      offerEmailNotifications: userSettings?.offerEmailNotifications,
      offerTextNotifications: userSettings?.offerTextNotifications,
    });
  }, [isSuccess]);

  const handleSave = async () => {
    setLoading(true);
    updateUserSettings({
      userId: currentUser.id,
      offerEmailNotifications: formFields.offerEmailNotifications,
      offerTextNotifications: formFields.offerTextNotifications,
    });
    try {
      const response = await updateAccount({
        phone: formFields.phone,
      });
      // Auth returns a string response instead of a number
      if (response.status === "success") {
        dispatch(
          enqueueSnackbar("Successfully updated account details.", {
            variant: "success",
          }),
        );
        //       }
        dispatch({
          type: "auth/userUpdateSuccess",
          payload: { user: response.data, headers: response.headers },
        });
      }
      setErrors({});
      updateQueryParams({ modal: null });
      dispatch(modalReducer.actions.close());
    } catch (error) {
      setErrors(deepChangeObjectKeysCase(error.data.errors, camelCase));
    }
    setLoading(false);
  };

  useEffect(() => {
    setIsFormValid(
      !formFields.offerTextNotifications ||
        formFields.phone.replace(/\D/g, "").length > 9,
    );
  }, [formFields]);

  return (
    <div className={classes.root} data-component="NotificationSettings">
      <div className={classes.subTitle}>Sellers have 24 hours to respond</div>
      <NotificationSettingsCard
        type="email"
        formFields={formFields}
        handleChange={handleChange}
        errors={errors}
      />
      <NotificationSettingsCard
        type="text"
        formFields={formFields}
        handleChange={handleChange}
        errors={errors}
      />
      <NotificationSettingsCard formFields={formFields} type="dashboard" />
      <LoadingButton
        className={classes.saveButton}
        fullWidth
        variant="contained"
        color="primary"
        loading={loading}
        onClick={handleSave}
        data-cy="save-button"
        disabled={!isFormValid}
      >
        Save
      </LoadingButton>
    </div>
  );
};

export default NotificationSettings;

NotificationSettings.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    phone: PropTypes.string,
  }).isRequired,
};
