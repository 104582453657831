import { makeStyles } from "@mui/styles";
import theme from "theme";

export const useStyles = makeStyles(() => ({
  root: {
    margin: 16,
  },
  animationContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  animation: {
    width: 300,
    height: 300,
  },
  sentText: {
    ...theme.design.sectionHeader,
  },
  verifyText: {
    ...theme.design.sectionSubheader,
    color: theme.palette.design.subheader,
    margin: "16px 0",
  },
  waitText: {
    ...theme.design.sectionSubheader2,
    color: theme.palette.design.header,
  },
}));
