import { makeStyles } from "@mui/styles";
import theme from "theme";

export const useStyles = makeStyles(() => ({
  root: ({ modal }) => ({
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    height: modal ? "unset" : "100vh",
    "@media only screen and (max-height: 500px)": {
      height: "71vh",
    },
    "@media only screen and (max-height: 350px)": {
      height: "unset",
    },
    marginBottom: 24,
  }),
  container: {
    display: "flex",
    justifyContent: "space-between",
  },
  submit: {
    marginTop: "15px",
    height: "55px",
  },
  input: {
    width: "100%",
  },
  button: {
    width: "100%",
    marginTop: 10,
    "& span": { justifyContent: "start" },
  },
  text: { marginTop: "10px", fontSize: "14px" },
  signInButton: {
    color: theme.palette.primary.main,
    paddingLeft: 0,
  },
}));
