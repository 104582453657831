import React, { useState } from "react";
import { useRouter } from "next/router";
import kebabCase from "lodash/kebabCase";
import startCase from "lodash/startCase";
import clsx from "clsx";
import PropTypes from "prop-types";

import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { signupWithEmail } from "requests/auth-api";
import { signInRoute, customerListingsRoute } from "routes";
import usePageWidth from "hooks/usePageWidth.hook";

import Legal from "components/content/Legal/Legal.component";
import ValidatedInput from "components/inputs/ValidatedInput/ValidatedInput.component";
import LoadingButton from "components/buttons/LoadingButton/LoadingButton.component";

import useUser from "hooks/useUser.hook";
import { localStorageSet } from "helpers/local-storage-helpers";
import { useQueryParams } from "hooks/useQueryParams.hook";
import { modalReducer } from "reducers/modal-reducer";

// Styles
import { useStyles } from "./SignUp.styles";

const initialCredentials = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  passwordConfirmation: "",
};

const SignUp = ({ modal, currentVDP, confirmSuccessUrl }) => {
  const classes = useStyles({ modal });
  const dispatch = useDispatch();
  const router = useRouter();
  const [credentials, setCredentials] = useState(initialCredentials);
  const [focus, setFocus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState();
  const { user } = useUser({
    redirectIfFound: true,
    redirectTo: modal ? null : customerListingsRoute,
  });
  const { isMobileWidth } = usePageWidth();
  const { enqueueSnackbar } = useSnackbar();
  const { updateQueryParams } = useQueryParams();

  if (user && modal) {
    dispatch(modalReducer.actions.close());
  }

  const handleChange = (event) => {
    const updated = { ...credentials };
    updated[event.target.name] = event.target.value;
    setCredentials(updated);
  };
  const handleFocus = () => {
    if (isMobileWidth) {
      setFocus(true);
    }
  };
  const handleBlur = () => {
    setFocus(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    signupWithEmail({
      ...credentials,
      confirmSuccessUrl,
    })
      .then(() => {
        enqueueSnackbar("Registered, please check your email", {
          variant: "success",
        });
        setLoading(false);
        if (modal) {
          if (currentVDP) {
            localStorageSet("location", currentVDP);
          }
          dispatch(modalReducer.actions.show({ modal: "verificationSent" }));
          updateQueryParams({ email: credentials.email });
        } else {
          router.push(signInRoute, undefined, { shallow: true });
        }
      })
      .catch((error) => {
        setErrors(error.data.errors);
        setLoading(false);
      });
  };

  const sharedInputProps = (key) => ({
    component: TextField,
    onChange: handleChange,
    variant: "outlined",
    onFocus: handleFocus,
    onBlur: handleBlur,
    id: kebabCase(key),
    label: startCase(key),
    margin: "normal",
    className: classes.input,
    name: key,
    value: credentials[key],
    "data-cy": `auth-${kebabCase(key)}`,
    errors,
  });

  return (
    <div
      className={clsx(classes.root, focus && classes.focusRoot)}
      data-cy="sign-up"
    >
      <div data-cy="auth-page" className={classes.component}>
        <Container maxWidth="xs">
          <Typography
            variant="h1"
            data-cy="auth-header"
            className={classes.header}
          >
            Sign up
          </Typography>
          <form data-cy="sign-up-form" onSubmit={handleSubmit}>
            <div className={classes.container}>
              <ValidatedInput {...sharedInputProps("firstName")} />
            </div>
            <div className={classes.container}>
              <ValidatedInput {...sharedInputProps("lastName")} />
            </div>
            <div className={classes.container}>
              <ValidatedInput {...sharedInputProps("email")} />
            </div>
            <div className={classes.container}>
              <ValidatedInput
                {...sharedInputProps("password")}
                type="password"
              />
            </div>
            <div className={classes.container}>
              <ValidatedInput
                {...sharedInputProps("passwordConfirmation")}
                label="Confirm Password"
                type="password"
              />
            </div>
            <LoadingButton
              className={classes.button}
              loading={loading}
              color="primary"
              size="large"
              variant="contained"
              fullWidth
              type="submit"
              data-cy="auth-sign-up-button"
            >
              Sign up
            </LoadingButton>
            <Legal />
            <div className={classes.text}>
              Already have an account?{" "}
              <Button
                className={classes.switchButton}
                variant="text"
                color="primary"
                onClick={() => {
                  modal
                    ? dispatch(modalReducer.actions.show({ modal: "signIn" }))
                    : router.push(signInRoute);
                }}
              >
                Sign in
              </Button>
            </div>
          </form>
        </Container>
      </div>
    </div>
  );
};

SignUp.propTypes = {
  modal: PropTypes.bool,
  currentVDP: PropTypes.string,
  confirmSuccessUrl: PropTypes.string,
};

SignUp.defaultProps = {
  modal: false,
  currentVDP: null,
  confirmSuccessUrl: process.env.NEXT_HOST + signInRoute,
};

export default SignUp;
