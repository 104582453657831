import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "theme";

export const useWidthDetection = () => {
  const dispatch = useDispatch();
  const desktopView = useSelector((s) => s.global.desktopView);
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  useEffect(() => {
    if (isMd !== desktopView) {
      dispatch({ type: "global/updateDesktopView", payload: isMd });
    }
  }, [isMd]);
};
