import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

const BotProtectedForm = ({ onSubmit, children, ...rest }) => {
  const random = Math.random().toString();
  const { invisible } = makeStyles(() => ({
    invisible: { display: "none" },
  }))();
  const handleSubmit = (event) => {
    if (event.target.honey.value !== random) {
      event.preventDefault();
    } else onSubmit(event);
  };

  return (
    <form onSubmit={handleSubmit} {...rest}>
      <input
        type="checkbox"
        name="honey"
        className={invisible}
        tabIndex="-1"
        autoComplete="off"
        defaultValue={random}
      />
      {children}
    </form>
  );
};

BotProtectedForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default BotProtectedForm;
