// External dependencies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import Image from "components/image-containers/Image/Image.component";
import { homeRoute, inventoryRoute, articlesRoute } from "routes";
import clsx from "clsx";

// Material UI Components
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

// Components
import GlobalModals from "components/modals/GlobalModals/GlobalModals.component";
import CustomNextLink from "components/links/CustomNextLink/CustomNextLink.component";

// Helpers
import { vinnLogoInverse, vinnLogo } from "assets/externalImages";
import { useWidthDetection } from "hooks/useWidthDetection.hook";
import { modalReducer } from "reducers/modal-reducer";

// Styles
import { useStyles } from "./Header.styles";

const Header = ({ logoColour, linkColour, noBorder }) => {
  const classes = useStyles({ noBorder });
  const [redirectOnSignIn, setRedirectOnSignIn] = useState();

  const router = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    if (router.query.redirectOnSignIn) {
      setRedirectOnSignIn(router.query.redirectOnSignIn);
    }
  }, [router.query.redirectOnSignIn]);

  const handleExpert = () => {
    dispatch(
      modalReducer.actions.show({ modal: "talkToVE", variant: "default" }),
    );
  };

  const links = [
    {
      type: "button",
      title: "Talk to a vehicle expert",
      onClick: handleExpert,
      dataCy: "vehicle-expert-link",
    },
    {
      type: "link",
      href: inventoryRoute,
      title: "Shop",
      dataCy: "shop-link",
    },
    {
      type: "link",
      href: articlesRoute,
      title: "Reviews and articles",
      dataCy: "articles-link",
    },
  ];

  const colourClass = linkColour === "white" ? classes.white : classes.black;
  const logoUrl =
    (logoColour || linkColour) === "white" ? vinnLogoInverse : vinnLogo;

  useWidthDetection();

  return (
    <div className={classes.root} data-component="Header">
      <AppBar position="static" className={classes.appBar} color="transparent">
        <div className={classes.toolBarContainer} data-cy="vinn-header">
          <Toolbar className={classes.toolBar}>
            <a
              href={homeRoute}
              aria-label="Navigate to Vinn homepage"
              data-cy="vinn-logo"
            >
              <Image
                src={logoUrl}
                width={110}
                height={42}
                alt="vinn logo"
                className={classes.logo}
                priority
                quality={100}
              />
            </a>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <Typography className={classes.links}>
                {links.map((link) =>
                  link.type === "link" ? (
                    <CustomNextLink
                      key={link.title}
                      href={link.href}
                      onClick={link.onClick}
                      className={clsx(colourClass, classes.link)}
                      aria-label={link.title}
                      data-cy={link.dataCy}
                    >
                      {link.title}
                    </CustomNextLink>
                  ) : (
                    <Button
                      variant="text"
                      key={link.title}
                      onClick={link.onClick}
                      className={clsx(
                        colourClass,
                        classes.link,
                        classes.button,
                      )}
                      aria-label={link.title}
                      data-cy={link.dataCy}
                    >
                      {link.title}
                    </Button>
                  ),
                )}
              </Typography>
            </div>
          </Toolbar>
        </div>
      </AppBar>
      <GlobalModals redirectOnSignIn={redirectOnSignIn} />
    </div>
  );
};

Header.propTypes = {
  logoColour: PropTypes.oneOf(["white", "black", undefined]),
  linkColour: PropTypes.oneOf(["white", "black"]),
  noBorder: PropTypes.bool,
};

Header.defaultProps = {
  noBorder: false,
  linkColour: "black",
  logoColour: undefined,
};

export default Header;
