import { vinnV1Api } from "services/vinn-api-v1";

const userApi = vinnV1Api.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: (params) => ({
        url: "/users",
        params,
      }),
      providesTags: () => [{ type: "Users" }],
    }),
    getUser: builder.query({
      query: ({ userId, ...params }) => ({
        url: `/users/${userId}`,
        params,
      }),
      providesTags: (_result, _error, { userId }) => [
        { type: "Users", id: userId },
      ],
    }),
    deleteUser: builder.mutation({
      query: (userId) => ({
        url: `/users/${userId}`,
        method: "DELETE",
      }),
    }),
    updateUserSettings: builder.mutation({
      query: ({ userId, ...params }) => ({
        url: `/users/${userId}/settings`,
        params,
        method: "PATCH",
      }),
      // optimistic update
      async onQueryStarted(
        { userId, ...params },
        { dispatch, queryFulfilled },
      ) {
        dispatch(
          vinnV1Api.util.updateQueryData(
            "getUser",
            { userId, view: "settings" },
            (draft) => {
              Object.assign(draft, {
                setting: { ...draft.setting, ...params },
              });
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          dispatch(
            vinnV1Api.util.invalidatesTags([{ type: "Users", id: userId }]),
          );
        }
      },
    }),
    uploadProfilePhoto: builder.mutation({
      query(data) {
        const { userId, blob } = data;
        return {
          url: `/users/${userId}`,
          method: "PATCH",
          data: { blob },
          headers: { "Content-Type": "image/jpeg" },
        };
      },
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserQuery,
  useDeleteUserMutation,
  useUpdateUserSettingsMutation,
  useUploadProfilePhotoMutation,
} = userApi;
