import { makeStyles } from "@mui/styles";
import theme from "theme";

export const useStyles = makeStyles(() => ({
  dialogTitle: {
    ...theme.design.dialogTitleBar,
  },
  dialogContent: {
    ...theme.design.dialogContent,
  },
  icon: {
    cursor: "pointer",
  },
}));
