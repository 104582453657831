// Dependencies
import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";

// Material UI Components

// Icons

// Components
import NotificationSettings from "features/main/components/PrivateSaleOffer/Notifications/NotificationSettings.component";
import ModalContainer from "components/modals/ModalContainer/ModalContainer.component";

// Helpers/Thunks/Hooks
import useUser from "hooks/useUser.hook";
import { useQueryParams } from "hooks/useQueryParams.hook";
import { modalReducer } from "reducers/modal-reducer";

// Styles

const NotificationSettingsModal = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { user: currentUser } = useUser();
  const { updateQueryParams } = useQueryParams();
  const { modal } = router.query;
  const currentModal = useSelector((s) => s.modal.modal);

  const handleClose = () => {
    dispatch(modalReducer.actions.close());
  };

  useEffect(() => {
    if (modal === "notificationSettings") {
      if (currentUser) {
        dispatch(modalReducer.actions.show({ modal }));
        updateQueryParams({ modal: null });
      } else {
        updateQueryParams({
          modal: "signIn",
          redirectOnSignIn: `${
            window.location.origin + window.location.pathname
          }?modal=notificationSettings`,
        });
      }
    }
  }, [modal]);

  return (
    <div
      data-component="NotificationSettingsModal"
      data-cy="notification-settings-modal"
    >
      <ModalContainer
        open={currentModal === "notificationSettings"}
        handleClose={handleClose}
        contentChildren={
          <NotificationSettings currentUser={currentUser || {}} />
        }
      />
    </div>
  );
};

export default NotificationSettingsModal;
