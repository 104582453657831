// Dependencies
import React from "react";

// Material UI Components
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// Assests
import { emailCheckmarkAnimation } from "assets/externalImages";

// Components

// Helpers/Thunks/Hooks
import { articlesRoute, inventoryRoute } from "routes";
import usePageWidth from "hooks/usePageWidth.hook";
import useLottieAnimations from "hooks/useLottieAnimations.hook";

// Styles

import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useStyles } from "./SentConfirmation.styles";

const SentConfirmation = ({ handleClose }) => {
  useLottieAnimations();
  const classes = useStyles();
  const { isMobileWidth } = usePageWidth();
  const variant = useSelector((state) => state.modal.variant);

  const renderButtonVariants = () => {
    switch (variant) {
      case "default":
        return (
          <DialogActions className={classes.actions}>
            <Button
              autoFocus
              href={articlesRoute}
              color="primary"
              variant="outlined"
              className={classes.button}
              fullWidth={isMobileWidth}
            >
              Learn
            </Button>
            <Button
              autoFocus
              href={inventoryRoute}
              color="primary"
              variant="contained"
              className={classes.button}
              fullWidth={isMobileWidth}
              data-cy="browse-button"
            >
              Browse
            </Button>
          </DialogActions>
        );
      default:
        return (
          <DialogActions className={classes.actions}>
            <Button
              autoFocus
              onClick={handleClose}
              color="primary"
              variant="contained"
              className={classes.button}
              fullWidth={isMobileWidth}
            >
              Continue
            </Button>
          </DialogActions>
        );
    }
  };

  return (
    <div
      className={classes.root}
      data-component="SentConfirmation"
      data-cy="sent-confirmation"
    >
      <DialogContent className={classes.dialogContentRoot}>
        <div className={classes.lottie}>
          <lottie-player
            src={emailCheckmarkAnimation}
            background="transparent"
            speed="1"
            autoplay
          />
        </div>
        <div className={classes.dialogTitle}>
          <Typography className={classes.dialogTitleText}>
            We received your inquiry.
            <br />
            One of our{" "}
            {variant === "default" ? "vehicle experts" : "listing agents"} will
            be in touch with you shortly.
          </Typography>
        </div>
        <div className={classes.dialogContent}>
          <Typography className={classes.dialogContentText}>
            In the meantime continue browsing our inventory or learn about car
            shopping on our blog!
          </Typography>
        </div>
      </DialogContent>

      {renderButtonVariants()}
    </div>
  );
};

export default SentConfirmation;

SentConfirmation.propTypes = {
  handleClose: PropTypes.func,
};

SentConfirmation.defaultProps = {
  handleClose: () => {},
};
